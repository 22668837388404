<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Alta de Persona</h1>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <PersonaForm :datosDni="datosDni"></PersonaForm>
    </div>
  </v-container>
</template>

<script>
import PersonaForm from "../components/PersonaForm.vue";

export default {
  name: "PersonaAlta",
  components: {
    PersonaForm,
  },
  data() {
    return {
      ingresos: null,
      datosDni: {},
    };
  },
  created() {
    if (
      this.$route.query.nombres &&
      this.$route.query.apellidos &&
      this.$route.query.documento &&
      this.$route.query.genero
    ) {
      let dDni = {
        nombres: this.$route.query.nombres,
        apellidos: this.$route.query.apellidos,
        documento: this.$route.query.documento,
        genero: this.$route.query.genero,
        nacimiento: this.$route.query.nacimiento,
      };
      this.datosDni = dDni;
    } else if (this.$route.query.documento) {
      let dDni = {
        documento: this.$route.query.documento,
      };
      this.datosDni = dDni;
    }
  },
};
</script>
