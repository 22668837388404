<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Persona</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div v-if="this.persona">
      <PersonaForm :persona="this.persona"></PersonaForm>
    </div>
  </v-container>
</template>

<script>
import PersonaForm from "../components/PersonaForm.vue";
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "PersonaEdit",
  components: {
    PersonaForm,
  },
  data() {
    return {
      persona: null,
    };
  },
  props: ["id"],
  created() {
    VisitasService.getPersona(this.id)
      .then((response) => {
        this.persona = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
