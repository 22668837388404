<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="4" md="2">
          <v-row>
            <v-col>
              <v-avatar size="200" color="black">
                <img
                  v-if="foto"
                  :src="foto.foto"
                  alt="foto"
                  class="photo-viewport"
                />
                <img
                  v-else-if="!camera && !cameraDone"
                  src="../assets/no-photo.png"
                  alt="no profile picture"
                />
                <img
                  v-else-if="cameraDone"
                  :src="fotoTaken"
                  alt="photo-taken"
                />
                <div v-if="camera" class="camera-viewport">
                  <EasyCamera
                    v-model="fotoTaken"
                    ref="cam"
                    :fullscreenZIndex="1"
                    @input="cameraCloseFS"
                  >
                  </EasyCamera>
                </div>
              </v-avatar>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn rounded @click="cameraOnOff()">
                <v-icon large> mdi-camera </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-spacer> </v-spacer>
              <v-btn rounded :disabled="!camera" @click="cameraShoot()">
                <v-icon large :color="camera ? 'success' : 'error'">
                  mdi-camera-iris
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- <div class="barcode_scan" v-if="camera">
            <EasyCamera v-model="picture" output="blob" ref="cam"> </EasyCamera>
            {{ picture }}
          </div>
          <div class="barcode_scan" v-if="cameraDone">
            <v-img max-height="150" max-width="250" :src="picture"></v-img>
          </div> -->
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <v-alert dense v-if="error" type="error">{{
            error.documento_nro[0]
          }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="onSubmit">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              :items="tipos"
              v-model.number="personatipo"
              item-text="nombre"
              item-value="id"
              label="Tipo Persona"
              value="1"
              required
            ></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="nombres"
              :counter="100"
              label="Nombres"
              required
              :rules="nombresRules"
              @input="nombres = nombres.toUpperCase()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="apellidos"
              :counter="100"
              label="Apellidos"
              required
              :rules="nombresRules"
              @input="apellidos = apellidos.toUpperCase()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              :items="generos"
              v-model.number="genero"
              item-text="nombre"
              item-value="id"
              label="Género"
              value="1"
              required
              :rules="selectRules"
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              :items="documentos"
              v-model.number="documentotipo"
              item-text="nombre"
              item-value="id"
              label="Documento Tipo"
              value="1"
              required
              :rules="selectRules"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="documentonumero"
              label="Documento Nº"
              :counter="11"
              :rules="documentoRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="unidadfuncional"
              label="Unidad Funcional"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" @click="addTelefonoForm">
              + Agregar Teléfono</v-btn
            >
          </v-col>
        </v-row>
        <TelefonoInputs
          v-for="(telefono, index) in telefonos"
          :key="index"
          :telefono="telefono"
          @telform-deleted="removeTelefonoForm"
        >
          {{ index }}
        </TelefonoInputs>
        <br />

        <v-divider> </v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!formValidity" color="info" type="submit"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/personas"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import VisitasService from "../services/VisitasService";
import TelefonoInputs from "./TelefonoInputs.vue";
import EasyCamera from "easy-vue-camera";
//import Integer from "@zxing/library/esm/core/util/Integer";

export default {
  name: "PersonaForm",
  components: {
    TelefonoInputs,
    EasyCamera,
  },

  data() {
    return {
      tipos: [],
      documentos: [],
      formValidity: false,
      personatipo: null,
      generos: null,
      genero: null,
      nombres: "",
      apellidos: "",
      scan: false,
      camera: false,
      cameraDone: false,
      documentotipo: null,
      telefonos: [],
      nacimiento: "",
      documentonumero: "",
      unidadfuncional: "",
      registrarVisita: false,
      foto: null,
      fotoTaken: null,
      error: false,
      nombresRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 100 ||
          "La longuitud del campo nombre debe ser menor o igual a 100 caracteres.",
      ],
      documentoRules: [
        (v) => !!v || "Debe ingresar un número de documento",
        (v) =>
          (v && v.length <= 11) ||
          "El documento no puede tener mas de 11 caracteres.",
        (v) =>
          (v && v.length > 6) ||
          "El documento no puede tener menos de 7 caracteres.",
        (v) => (v && !isNaN(v)) || "Debe ingresar un número.",
      ],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
    };
  },
  props: {
    datosDni: {
      type: Object,
      required: false,
    },
    persona: {
      type: Object,
      required: false,
    },
  },
  created() {
    VisitasService.getTiposPersona()
      .then((response) => {
        this.tipos = response.data;
      })
      .catch((error) => console.log(error));
    VisitasService.getTiposDocumento()
      .then((response) => {
        this.documentos = response.data;
      })
      .catch((error) => console.log(error));
    VisitasService.getGeneros()
      .then((response) => {
        this.generos = response.data;
      })
      .catch((error) => console.log(error));

    //this.$refs.cam.close();

    // Datos desde el escáner
    if (this.datosDni && this.datosDni.nombres) {
      this.nombres = this.datosDni.nombres;
      this.apellidos = this.datosDni.apellidos;
      this.documentonumero = this.datosDni.documento;
      this.documentotipo = 1;
      this.personatipo = 3;
      this.genero = this.datosDni.genero;
      this.registrarVisita = true;
    } else if (this.datosDni && this.datosDni.documento) {
      this.documentonumero = this.datosDni.documento;
      this.documentotipo = 1;
      this.personatipo = 3;
      this.registrarVisita = true;
    }

    if (this.persona) {
      this.nombres = this.persona.nombres;
      this.apellidos = this.persona.apellidos;
      this.documentonumero = this.persona.documento_nro;
      this.documentotipo = this.persona.documento_tipo;
      this.personatipo = this.persona.tipo;
      this.genero = this.persona.genero;
      this.unidadfuncional = this.persona.unidad_funcional;
      this.telefonos = this.persona.telefonos;
      this.loadProfilePicture();
    }

    //this.telefonos.push(this.createTelefono());
  },

  methods: {
    // onDecode(result) {
    //   // 00111671111@APELLIDO@NOMBRE@M@28123456@A@10/11/1988@07/06/2015@702
    //   this.apellidos = result.split("@")[1];
    //   this.nombres = result.split("@")[2];
    //   let gen = result.split("@")[3];
    //   if (gen == "M") this.genero = 1;
    //   else if (gen == "F") this.genero = 2;
    //   else if (gen == "X") this.genero = 3;
    //   this.documentonumero = result.split("@")[4];
    //   this.nacimiento = result.split("@")[6];
    //   this.scan = false;
    // },

    loadProfilePicture() {
      VisitasService.getPersonaFoto(this.persona.id).then((response) => {
        this.foto = response.data;
      });
    },
    cameraCloseFS() {
      this.cameraDone = true;
      this.camera = false;
    },

    cameraShoot() {
      this.cameraDone = true;
      this.$refs.cam.snap();
      this.camera = true;
      this.$refs.cam.close();
      //this.camera = false;
    },

    cameraOnOff() {
      this.camera = true;
      this.cameraDone = false;
      this.fotoTaken = null;
      this.$refs.cam.start();
      // if (!this.camera) {
      //   this.camera = !this.camera;
      //   this.$refs.cam.start();
      // } else {
      //   this.camera = !this.camera;
      //   this.$refs.cam.close();
      // }
      // this.cameraDone = false;
    },

    onSubmit() {
      let personaInstance = {
        nombres: this.nombres,
        apellidos: this.apellidos,
        documento_tipo: this.documentotipo,
        documento_nro: this.documentonumero,
        unidad_funcional: this.unidadfuncional,
        tipo: this.personatipo,
        //foto: this.picture
        genero: this.genero,
        telefonos: this.telefonos,
      };
      if (!this.persona) this.personaNueva(personaInstance);
      else this.personaActualizar(personaInstance);
    },

    personaActualizar(personaInstance) {
      personaInstance.id = this.persona.id;
      VisitasService.putPersona(personaInstance)
        .then((response) => {
          if (this.fotoTaken && this.foto)
            this.uploadFoto(this.persona.id, true);
          if (this.fotoTaken && !this.foto) this.uploadFoto(this.persona.id);

          this.$router.push({
            name: "PersonaDetalle",
            params: { id: personaInstance.id },
          });
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },

    personaNueva(personaInstance) {
      // console.log(personaNueva);
      VisitasService.postPersona(personaInstance)
        .then((response) => {
          // Si existe foto
          if (this.fotoTaken) this.uploadFoto(response.data.id);
          if (this.registrarVisita) {
            this.$router.push({
              name: "Ingreso",
              params: { documento: this.documentonumero },
            });
          } else {
            this.$router.push({
              name: "Personas",
            });
          }
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },

    uploadFoto(id_persona, actualizar = false) {
      let fotoObj = {
        persona: id_persona,
        foto: this.fotoTaken,
      };

      if (actualizar)
        VisitasService.putPersonaFoto(fotoObj).catch((error) => {
          alert(error.response.data);
        });
      else VisitasService.postPersonaFoto(fotoObj);
    },

    validate() {
      this.$refs.form.validate();
    },
    addTelefonoForm() {
      this.telefonos.push(this.createTelefono());
    },
    removeTelefonoForm(telefono) {
      if (this.telefonos.length > 0)
        this.telefonos.splice(this.telefonos.indexOf(telefono), 1);
    },
    createTelefono() {
      telefono = null;
      let telefono = {
        id: null,
        persona_id: null,
        numero: "",
        tipo: null,
      };
      return telefono;
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
